import i18n from '@/plugins/vue-i18n'
import validations from '../locales/validations'
const TARGET_NODE = process.env.VUE_ENV === 'server'

function getSeoInfo(vm) {
  const { seoInfo } = vm.$options
  if (seoInfo) {
    return seoInfo.call(vm)
  }
}

function updateSeoInfo(vm, vdom) {
  const seoInfo = getSeoInfo(vm)
  vdom.lang = i18n.locale
  vdom.title = (seoInfo && seoInfo.title) || i18n.t('大豐銀行')
  vdom.description = (seoInfo && seoInfo.description) || ''
  vdom.keywords = (seoInfo && seoInfo.keywords) || ''
  vdom.metas =
    (seoInfo && seoInfo.metas) ||
    '<meta name="insight-app-sec-validation" content="6bf5da92-7aa1-4166-87f7-27eef3f74b1a" />'
    
}

function serverUpdateSeoInfo() {
  updateSeoInfo(this, this.$ssrContext)
}

function clientUpdateSeoInfo() {
  updateSeoInfo(this, document)
}

export default TARGET_NODE ? serverUpdateSeoInfo : clientUpdateSeoInfo
