<template>
  <div class="jumbotron">
    <b-container class="mt-4">
      <!--<h1 class="my-4">{{$t('產品詳情')}}</h1>-->
      <template v-if="newsDetail">
        <div class=" my-3">
          <h2 class="blue">{{ newsDetail.title }}</h2>
          <!-- <p>{{ newsDetail.inputAt }}</p> -->
          <!-- <b-img v-if="newsDetail.cover" :src="newsDetail.cover"></b-img> -->
          <div class="my-2" v-html="newsDetail.content"></div>
        </div>
      </template>
      <template v-else>
        <template v-if="show">
          <h1 class="my-4">{{ $t('提示') }}</h1>
          <div class="page-content mb-5">{{ $t('此頁面正在建設中...') }}</div>
        </template>
      </template>
    </b-container>

    <div class="text-center my-5">
      <b-link rel="" class="btn btn-round news_more" @click="$router.back(-1)">{{ $t('返回') }} </b-link>
    </div>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  data() {
    return {
      newsDetail: null,
      title: null,
      show: false
    }
  },
  computed: {
    ...mapState('page', ['newsDetails']),
    ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    }
  },
  watch: {},
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = this.$route.name
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_NEWS_DETAIL', {
            cookies,
            id: this.$route.params.id
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      // if (this.page){ this.title = this.page.title}
      if (await this.newsDetails) {
        this.newsDetail = this.newsDetails
      } else {
        // this.$router.push({
        //   name: 'home'
        // })
      }
      this.show = true
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {
    return this.fetchData()
  },
  mounted() {
    this.fetchData()
  },
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
