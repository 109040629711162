export default {
  beforeCreate() {
    console.log(`[${process.env.VUE_ENV}]beforeCreate`)
  },
  created() {
    console.log(`[${process.env.VUE_ENV}]created`)
  },
  serverPrefetch() {
    console.log(`[${process.env.VUE_ENV}]serverPrefetch`)
  },
  beforeMount() {
    console.log(`[${process.env.VUE_ENV}]beforeMount`)
  },
  mounted() {
    console.log(`[${process.env.VUE_ENV}]mounted`)
  }
}
